<template>
      <v-card v-if="!loading & !isComplete" 
        elevation="2"
     
      >
       <v-toolbar
            color="#4ECDC4"
            dark
            flat
        ><span class="text-h6">新規お問合せ</span>
          <v-spacer></v-spacer>
        <v-btn 
          icon
          @click="close"
        > 
          <v-icon>{{icons.close}}</v-icon>
        </v-btn>
        </v-toolbar>
      
        <v-form  class="px-4 " style="overflow-y:auto">
          <v-container >
            <v-text-field
              v-model="sendData.name"
              outlined
              maxlength="50"
              label="お名前"
              :rules="rules"
            ></v-text-field>

            <v-text-field
              v-model="sendData.email"
              outlined
              maxlength="50"
              label="連絡用メールアドレス(任意)"
              hint="※登録したメールアドレスと連絡用のメールアドレスが異なる場合"
            ></v-text-field>

            <p class="text--secondary">お問い合わせの種類</p>
             <v-radio-group
              v-model="sendData.type"
              column
              mandatory
              class="pl-5"
            >
              <v-radio
                label=" アカウントや請求について"
                color="narekomu"
                value="0"
              ></v-radio>
                <v-radio
                label="データ容量の追加について"
                color="narekomu"
                value="1"
              ></v-radio>
                <v-radio
                label="バグや不具合について"
                color="narekomu"
                value="2"
              ></v-radio>
                <v-radio
                label="その他"
                color="narekomu"
                value="3"
              ></v-radio>
              
          
            </v-radio-group>


            <v-textarea
              v-model="sendData.details"
              outlined
              clearable
              name="input-7-4"
              label="お問い合わせ内容(必須)"
              :rules="rules"
            ></v-textarea>

               <v-row class="justify-center">
                <v-btn
                  outlined
                  color="narekomu"
                  class="white--text my-8  "
                  width="100px"
                  @click="createInquiry()"
                >
                  送信
                </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
       <v-card  v-else-if="isError" >
          <DialogError
            v-on:closeErrorEmit="close"
            text="エラーが発生し,お問い合わせの送信が完了しませんでした。"
          />
       </v-card>
        <v-card v-else-if="isComplete"  >
          <DialogComplete
            v-on:closeEmit="close"
            text="送信が完了しました。担当者からメールが来ます。"
          />
        </v-card>
       <v-card v-else
            color="#4ECDC4"
            dark
          >
          <v-card-text>
            送信中...
            <br>

            <v-progress-linear
              indeterminate
              color="white"
              
            ></v-progress-linear>
          </v-card-text>
        </v-card>

</template>

<script>
import axios from "axios"
import { mdiClose } from '@mdi/js';
const DialogComplete = () => import('@/components/DialogComplete.vue');
const DialogError = () => import('@/components/DialogError.vue');

  export default {
    name: 'Inquiry',
    components:{
      DialogComplete,
      DialogError
    },

    data: () => ({
      icons:{"close":mdiClose},
      loading:false,
      isError:false,
      isComplete:false,
      sendData:{
        name:null,
        email:null,
        type:null,
        details:null,
      },
      rules: [
        v => !!v || "必須項目です。",
      ],
    }),
    methods: {

      close (){
        this.loading = false;
        this.isError = false;
        this.isComplete = false;
        this.sendData.name = null;
        this.sendData.email = null;
        this.sendData.type = "0";
        this.sendData.details = null;
        this.$emit('closeEmit')
      },

      //新規問い合わせの生成
      createInquiry(){
        this.loading = true;
        if(!this.sendData.email)  this.sendData.email = this.$store.state.user_model.userData.userId; 
        // console.log(this.sendData.email)
        axios({
          method: "POST",
          url:`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/inquiries`,
          headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId, "Content-Type":"application/json" },
          data:this.sendData,
        },
        ).then((response) => {
          let id = response.data.results;
          this.loading = false;
          this.isComplete = true;
          this.$emit('createInquiryEmit',id,this.sendData);

        }).catch(reason => {
          console.log(reason)
          this.isError = true;
        });

      }

    },
  }
</script>


<style scoped>

  .Title > h1 {
    color: gray;
    font-weight: 200;
    border-bottom: 1px solid gray;
  }

</style>

